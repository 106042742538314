html, body {
    height: 100%; 
    margin: 0;
    padding: 0; 
}

*, 
*:before, 
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #F0F0F0;
    font-family: 'Sarabun', sans-serif;
    min-width: 320px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

/* Hide scrollbars */

*::-webkit-scrollbar {
    width: 0!important;
    height: 0!important;
}
