@mixin layout-padding {
    padding: map-get( $spacers, 4 );
    @include media-breakpoint-up(xxl) {
        padding: map-get( $spacers, 4 ) map-get( $spacers, 6);
    }
}

@mixin visually-hidden-mobile {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

@mixin max-viewport-height {
    height: calc(100dvh - ($max-element-height * 2) + $max-mobile-nav-height);
    @include media-breakpoint-up(xxl) {
        height: calc(100dvh - $max-element-height * 3);
    }
}

@mixin max-filter-viewport-height {
    height: calc(100dvh - ($max-element-height * 3) + $max-mobile-nav-height);
    @include media-breakpoint-up(xxl) {
        height: calc(100dvh - $max-element-height * 5);
    }
}