.button {
    display: flex;
    width: max-content;
    align-items: center;
    padding: .25em .5em;
    color: white;
    font-weight: 600;
    background-color: $secondary-colour;
    svg {
        width: 1.75em;
        height: 1.75em;
        margin-left: .25em;
    }
}