.siteBanner {
    @include layout-padding;
    display: flex;
    align-items: center;
    height: $max-element-height;
    color: white;
    background-image: radial-gradient(129.86% 1550.83% at 100% 100%, #009FE3 0%, #0A193F 50%, #000000 100%);
}

body:not(.MarketShareView) {
    .siteBanner {
        @include media-breakpoint-down(xxl) {
            @include visually-hidden-mobile
        }
    }
}