/* Chart wrapper styling based on context */

.chart-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.chart-wrapper.table-chart-wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
    border-collapse: collapse;
    height: calc(100% - $max-element-height * 1.25);
    overflow-y: scroll;

    // td {
    //     @media only screen and (max-height: 800px) {
    //         height: 60px!important;
    //     }
    // }

    tbody {
        @include media-breakpoint-down(lg) {
            max-width: 50vw;
        }
        @include media-breakpoint-up(lg) {
            width: 75%;
        }
    }

    thead {
        background-color: $light-grey-colour;
        height: 100%;
        position: relative;
        width: 50vw;
        @include media-breakpoint-up(lg) {
            width: 25%;
        }

        tr {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        tr td {
            border-bottom: 1px solid lighten($medium-grey-colour, 20);
            @include media-breakpoint-up(lg){
                border-right: 1px solid  $dark-grey-colour;
            }   
            @include media-breakpoint-down(lg) { 
                font-size: small;
            } 
        }

        tr td {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 10em;
        }

        tr td + td {
            @include media-breakpoint-up(lg){
                border-left: 1px solid lighten($medium-grey-colour, 20);
            }
        }

        td {
            @include layout-padding;
            font-weight: 600;
            min-height: calc(100dvh / 8);
            height: calc((100dvh - $max-element-height * 3 - ($max-element-height * 1.25)) / 8);
        }

        .table-chart-header-footer td {
            // background-color: lighten($medium-grey-colour, 20);
            color: $primary-colour;
            border-bottom: 0;
        }
    }
}

// Table header

.table-chart-header {
    @include layout-padding;
    display: flex;
    align-items: center;
    width: 25%;
    height: calc($max-element-height * 1.25 );
    border-bottom: 1px solid lighten($medium-grey-colour, 20);
    border-right: 1px solid ;

    @include media-breakpoint-up(lg) {
        // width: 30%;
        // max-width: 25%;
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.MarketShareView .chart-wrapper,
.OverviewView .chart-wrapper {
    flex-direction: row-reverse; 
    justify-content: center;

    @media (max-width: 768px) {
        padding-right: 10%; // Apply padding-right only on mobile
        justify-content: right; // Align to the right instead of center
    }
}

// Labels

.chart-wrapper .period-label {
    padding: 1em 0;
    text-align: center;
    font-weight: bold;
    color: black;
}

// Values

.chart-wrapper .values-row {
    display: flex;
    justify-content: center;
    gap: 1em;
    font-size: small;
    font-weight: 600;
}

.OverviewView .chart-wrapper .values-row {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

// Legends

.legend-text {
    white-space: nowrap;
    overflow: hidden;
    color: black;
}

.chart-wrapper .legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em 1em 3em 1em;
    font-size: small;
}

.chart-wrapper .legend-item {
    display: flex;
    align-items: center;

    .legend-color {
        width: 1em;
        height: 1em;
        margin-right: .5em;
    }
}

// Charts

svg.half-circle-chart .half-radial-path-group {
    transform: translateX(-50%)
}

.DetailsView {
    @media screen and (max-width: 768px) {
        .displayNone{
            display: none !important;
        }
    }

    #historicalGraphData{
        background-color: #009FE3;
    }
    .chart-wrapper:not(.table-chart-wrapper) {
        padding: 0 1em;
    }

    // .chart-wrapper{
    //     justify-content: center;
    //     max-height: calc(100dvh - ($max-element-height * 3.5) - ($max-element-height * 1.25));

    //     .doughnut-chart {
    //         margin: 1rem 0;
    //     }
    // }

    table.table-chart {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        @include media-breakpoint-down(lg) { 
            min-width: 50vw; 
            td {
                font-size: small;
            }
        }

        .table-chart-heading {
            font-weight: 600;
            color: $primary-colour;
        }
        
        tbody tr td tr:last-child td.table-chart-value {
            border-bottom: 0;
        }

       tr {
            display: table;
            width: 100%;
        }

        tr td {
            border-bottom: 1px solid lighten($medium-grey-colour, 20);
            text-align: left;
            width: 50%;
            height: calc((100dvh - $max-element-height * 3 - ($max-element-height * 1.25)) / 8);
            padding: 0;
        }

        tr td tr td {
            @include layout-padding;
        }

        .table-chart-column {
            background-color: white;
            border: 0;
            padding: 0;
        }

        tr td + td {
            @include media-breakpoint-up(xxl) {
                border-left: 1px solid lighten($medium-grey-colour, 20);
            }
        }

        td.table-chart-value {
            max-width: 2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include media-breakpoint-down(lg) {
                font-weight: 600;
                color: $secondary-colour;
            }
        }

        .table-chart-footer td {
            @include layout-padding;
            color: white;
            background-color: $secondary-colour;
            border-bottom: 0;
            a {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 90%;
                display: block;
            }
            svg {
                vertical-align: middle;
                margin-right:.25em;
                transform: translateY(-3px);
            }
        }
    }
    // Linear Chart
    svg.linear-chart {
        height: 100%;
        width: 100%;
    }

    // Doughnut Chart
    // .chart-wrapper.doughnut-chart-wrapper {}
    // canvas.doughnut-chart {}
    

    // Stacked Bar Chart
    .stacked-bar-chart-wrapper {
        height: 100%;
        padding: 1em 0;
    }
    canvas.stacked-bar-chart {
        width: 12rem!important;
        height: auto!important;
    }

    .chart-wrapper.stacked-area-chart-wrapper {
        // height: 50%;
        // width: 50%;
        justify-content: center;
    }

    canvas.stacked-area-chart {
        // height: calc(100% - $max-element-height * 1.25)!important;
        width: 100%!important;
        // min-height: 20rem!important;
    }


    canvas.doughnut-chart {
        height: 25vh!important;
    }

    .swiper-slide:not(:first-child) .chart-wrapper .legend,
    .fer-graphWrapper:not(:first-child) .chart-wrapper .legend {
        @include media-breakpoint-up(lg) {
            opacity: 0;
        }
    }
}

.OverviewView{
    // Linear Chart
    svg.linear-chart {
        width: 10vw;
        height: 10vw;
    }
    // Half-Circle Chart
    svg.half-circle-chart {
        width: 7vw;
        height: 7vw;
    }
    // Doughnut Chart
    canvas.doughnut-chart {
        width: 7vw!important;
        height: 7vw!important;
    }
    canvas.stacked-area-chart {
        width: 30vw!important;
        height: auto!important;
        @include media-breakpoint-up(md){
            width: 20vw!important;;
        }
        @include media-breakpoint-up(lg){
            width: 15vw!important;;
        }
    }
    .stacked-bar-chart-wrapper {
        width: 7rem!important;
        height: 5rem!important;
        @include media-breakpoint-up(lg){
            width: 6.5rem!important;
            height: 90%!important;
        }
    }

    .chart-wrapper .values-row {
        position: absolute;
        bottom: -.75em;
        &.previous {
            display: none;
        }
    }

    .chart-wrapper .period-label {
        display: none;
    }
    
    .chart-wrapper .legend {
        display: none;
    }
}

.MarketShareView {
    .stacked-area-chart-wrapper {
        width: 100%;
    }

    .stacked-bar-chart-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 768px) {
        .stacked-bar-chart-wrapper {
            width: 12.5rem; /* Apply this width on devices larger than 768px */
        }
    }
}