.filterPanel-wrapper {
    background-color: $primary-colour;
    position: relative;
    z-index: 3;
    flex: 1;
    @include max-viewport-height;
    @include media-breakpoint-down(xxl) {
        position: fixed;
        top: calc($max-element-height + $max-mobile-nav-height);
        width: 100%;
        height: calc(100dvh - $max-element-height - $max-mobile-nav-height);
        transform: translateX(100%);
        transition: .5s;
        z-index: 10;

        &.is-active {
            transform: translateX(0%);
        }
    }
}
.filterPanel-inner-wrapper {
    overflow: scroll;
    @include media-breakpoint-down(xl) {
        height: calc(100dvh - $max-element-height * 3 - $max-mobile-nav-height);
    }
}

.filterPanel-wrapper-header {
    display: flex;
    align-items: center;
    height: $max-element-height;
    font-weight: 600;
    padding: 1.75em 1em;
    background-color: $light-grey-colour;
    color: black;
    @include media-breakpoint-down(xxl) {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    @include media-breakpoint-up(xxl) {
        padding: 1.25em 1em;
        background-color: black;
        color: $medium-grey-colour;
    }
}

.filterPanel-wrapper-header p {
    margin: 0;
}

// Form

.filterPanel-form {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    @include media-breakpoint-up(xxl) {
        @include max-filter-viewport-height;
    }
}

// Checkboxes

.filterPanel-form-input-wrapper {
    width: 50%;
    @include media-breakpoint-up(xxl) {
        width: 100%;
    }
}

.filterPanel-form-checkbox {
    position: relative;
}

.filterPanel-form-checkbox label {
    padding: 1.75em 1em 1.75em 2.5em;
    border-left: .5em solid $primary-colour;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    @include media-breakpoint-up(xxl) {
        padding: 1em 1em 1em 2em;
    }
}

.filterPanel-form-checkbox input[type="checkbox"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid $off-blue-colour;
    background-color: $dark-off-blue-colour;
    margin: 0 .5em 0 0;

    &::before {
        content: "";
        width: 1em;
        height: 1em;
    }
}

.filterPanel-form-checkbox label:hover,
.filterPanel-form-input-wrapper {
    input[type="checkbox"]:checked + label {
        background-color: $dark-off-blue-colour;
    }
}

/*.filterPanel-form-input-wrapper:nth-child(1n) {
    input[type="checkbox"]:checked + label {
        border-color: $blue;
    }
    input[type="checkbox"]:checked {
        background-color: $blue;
    }
}

.filterPanel-form-input-wrapper:nth-child(2n) {
    input[type="checkbox"]:checked + label {
        border-color: $green;
    }
    input[type="checkbox"]:checked {
        background-color: $green;
    }
}

.filterPanel-form-input-wrapper:nth-child(3n) {
    input[type="checkbox"]:checked + label {
        border-color: $yellow;
    }
    input[type="checkbox"]:checked {
        background-color: $yellow;
    }
}*/

// Footer

.filterPanel-footer {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: $dark-grey-colour;
    bottom: $max-element-height;
    height: $max-element-height;
    @include media-breakpoint-up(xxl) {
        width: 25%;
    }
}

.filterPanel-footer-button {
    all: unset;
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    width: 50%;

    .filterPanel-footer-button-text {
        width: calc(100% - 20px);
        padding: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    svg {
        width: 20px;
        margin: .25rem;
        flex-shrink: 0;
        transform: translateX(-.5em);
    }

    &.next {
        .filterPanel-footer-button-text {
            text-align: right;
        }
    }

    &.previous {
        svg {
            transform: rotate(180deg) translateX(-.5em);
        }
    }
}

// Toggle

.filterPanel-form-apply-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: $secondary-colour;
    color: white;
    @include media-breakpoint-up(xxl) {
        display: none;
    }

    &:hover {
        background-color: darken($secondary-colour, 5);
    }

    &:disabled {
        color: rgba(255,255,255,0.6);
        svg {
            opacity: 0.6;
        }
    }
}

.filterPanel-form-scroll-text {
    color: white;
    position: fixed;
    padding: 1em;
    right: 1em;
    pointer-events: none;
    bottom: calc($max-element-height * 2);
    transition: .25s;
    transition: .5s;
    opacity: 0;
}

.filterPanel-form.has-overflow .filterPanel-form-scroll-text {
    opacity: .75;
}

.filterPanel-form.has-scrolled .filterPanel-form-scroll-text,
.filterPanel-form:hover .filterPanel-form-scroll-text {
    opacity: 0;
}