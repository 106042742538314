.spinner-icon {
  margin: 0 auto;
  border: 0.25rem solid #0094C7;
  border-top: 0.25rem solid transparent;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}