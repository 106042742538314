dl {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
}

dt {
    clear: both;
    width: 75%;
}

dt, dd {
    color: $dark-off-blue-colour;
    font-weight: 600;
    float: left;
    padding: 0;
    margin: .5em 0;
    @include media-breakpoint-up(xxl) {
        margin: 1.25em 0;
    }
    @media only screen and (max-height: 940px) {
        margin: .5em 0;
    }
}

dd {
    text-align: right;
    color: $secondary-colour;
    font-weight: 600;
    width: 25%;
}