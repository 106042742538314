.fer-rowWrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
}

.fer-graphWrapper {
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    flex-grow: 1;
    transition: .25s;
    &:hover {
        text-decoration: none;
    }
}


.fer-graphWrapper-header {
    min-height: 4rem;
    width: 100%;
    p {
        margin-top: .5em;
    }
}

.fer-graphWrapper-header-title {
    margin: 0;
    font-size: 1.125em;
}

.fer-graphBanner-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-self: flex-end;
    height: 100%;
    // height: $max-compare-height;
}

.fer-graphBanner-button-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
}

.fer-graphBanner-button {
    @include layout-padding;
    display: flex;
    flex: 1;
    align-items: center;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    color: black;
    justify-content: space-between;
    min-height: calc($max-element-height);
    // height: calc($max-element-height );
    height: 100%;
    border-bottom: 4px solid;
    background-color: $light-grey-colour;
    width: 100%;
}

/*
.fer-graphBanner-button-wrapper:nth-child(1n) .fer-graphBanner-button {
    border-color: $yellow;
}
.fer-graphBanner-button-wrapper:nth-child(2n) .fer-graphBanner-button {
    border-color: $green;
}
.fer-graphBanner-button-wrapper:nth-child(3n) .fer-graphBanner-button {
    border-color: $blue;
}
*/

button.fer-graphBanner-button-close {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: .25rem;
    right: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    z-index: 1;
}

button.fer-graphBanner-button-close:disabled {
    opacity: .25;
}

.fer-graphBanner-button-title {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Flex active graph panel banner

.fer-graphBanner-button-wrapper {
    @include media-breakpoint-down(lg) {
        transition: .25s;
        &.is-active {
            flex: 4;
        }
        &:not(.is-active) .fer-graphBanner-button-title {
            display: none;
        }
    }
}
 
.OverviewView .fer-graphWrapper-logo {
    position: absolute;
    max-width: 3.5rem;
    align-self: flex-start;
}

.OverviewView .is-detail-view .fer-graphWrapper > .fer-graphWrapper-logo {
    @media (max-width: 768px) {
        padding: 0.5em !important;
    }
}


.DetailsView .fer-graphWrapper-logo {
    max-width: 3.5rem;
}

.market-view .fer-graphWrapper {
    padding: 1em;
}

.fer-gridWrapper {
    --gap: .25em;
    --line-offset: calc(var(--gap) / 2);
    --line-thickness: 1px;
    --line-color: black;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    overflow: hidden;
    padding-bottom: 76px;
 
    .fer-graphWrapper {
        position: relative;

        @media (min-width: 769px) {
            padding: 1em;
        }

        &::before, 
        &::after {
            content: '';
            position: absolute;
            background-color: var(--line-color);
            z-index: 1;
        }

        &::after {
            inline-size: 100vw;
            block-size: var(--line-thickness);
            inset-inline-start: 0;
            inset-block-start: calc(var(--line-offset) * -1);
        }

        &::before {
            inline-size: var(--line-thickness);
            block-size: 100dvh;
            inset-block-start: 0;
            inset-inline-start: calc(var(--line-offset) * -1);
        }
    }
}

// Overrides

.OverviewView {
    .clubOverview-grid-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
        height: 100%;;
        width: 100%;
        padding: 1rem;
        .fer-graphWrapper-logo {
            max-width: 8rem;
            max-height: 6vw;
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% - .75em));
            @include media-breakpoint-up(lg) {
                transform: translate(-50%, -50%);
            }
        }

        h3 {
            text-align: left;
            color: black;
        }
    }
}

.OverviewView {
    .fer-gridWrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(6, minmax(0, calc((100dvh - $max-element-height * 2 - $max-mobile-nav-height) / 6)));
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: repeat(4, calc((100dvh - $max-element-height * 2 - $max-mobile-nav-height) / 4));
        }
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-template-rows: repeat(3, minmax(0, calc((100dvh - $max-element-height * 3) / 3)));
        }
    }

    .fer-graphWrapper {
        background-color: white;
    }
}

.MarketShareView {
    .fer-gridWrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(3, minmax(0, calc((100dvh - $max-element-height * 4) / 2)));
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: repeat(2, minmax(0, calc((100dvh - $max-element-height * 3) / 2)));
        }
    }
}

.MarketShareView {
    overflow: scroll;
    main {
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }
    .fer-gridWrapper {
        @include media-breakpoint-down(lg) {
            border-top: 1px solid black;
        }
    }
    .fer-graphWrapper {
        background-color: $light-grey-colour;
        canvas.doughnut-chart {
            height: 15vw!important;
            width: auto!important;
            @include media-breakpoint-up(sm) {
                height: 10vw!important;
            }
        }

        canvas.stacked-bar-chart {
            width: 100%!important; 
            height: auto!important;  
            max-width: 100%!important;
            
            /* For smaller screens (mobile-first approach) */
            @include media-breakpoint-up(sm) {
                height: calc(100dvh / 8)!important;  /* Set height as a fraction of viewport height */
                width: auto!important;  /* Let the width adjust according to the aspect ratio */
            }
        
            /* For larger screens (e.g., XL screens) */
            @include media-breakpoint-up(xl) {
                height: calc(100dvh / 4)!important;  /* Adjust height as needed */
                width: auto!important;  /* Keep width proportional */
            }
        }
        
    }
}

.DetailsView {
    .mainView:not(.is-table-view) .fer-rowWrapper {
        @include media-breakpoint-down(lg) {
            overflow-x: scroll;
            width: 100vw;
            .fer-graphWrapper {
                overflow: scroll;
                min-width: 100vw;
            }
        }
    }

    .fer-graphWrapper {
        background-color: white;
    }

    .chart-wrapper.table-chart-wrapper,
    .mainView:not(.is-table-view) .fer-graphWrapper {
        height: calc(100dvh - ($max-element-height * 2) - ($max-mobile-nav-height * 2));
        @include media-breakpoint-up(xxl) {
            /*height: calc(100dvh - $max-element-height * 4.25);*/
        }
    }

    .fer-graphBanner-button-wrapper + .fer-graphBanner-button-wrapper,
    .fer-graphWrapper + .fer-graphWrapper {
        @include media-breakpoint-up(lg) {
            border-left: 1px solid $dark-grey-colour!important;
        }
    }

    .fer-graphBanner-button-wrapper.is-active .fer-graphBanner-button {
        @include media-breakpoint-down(lg) {
            background-color: white;
        }
    }
    
}