main {
    display: flex;
}

.clubCarousel-panel-wrapper {
    flex: 1;
    background-color: #FFFFFF;
}

.mainView-wrapper {
    flex: 3;
}

.main {
    @include max-viewport-height;
}