/* Splash styles */

.splash {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100dvh;
    width: 100%;
    padding: 4rem;
    opacity: 1;
    overflow: hidden;
    background-color: $primary-colour;
    background-image: url(../assets/home/2024/splash-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.splash-header,
.splash-footer {
    width: 100%;
    opacity: 0;
    animation: fadeIn forwards 2s;
}

.splash-logo,
.splash-title img,
.splash-quote img {
    margin: 0 auto;
}

.splash-title {
    margin: 4em 0;
}

.splash-quote {
    font-size: 2.5em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: "EB Garamond", serif;
    color: white;
    margin: 1em 0;
    @include media-breakpoint-up(xxl) {
        margin: 3em 0;
    }
}

.splash-footer-logos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 1rem;
    img {
        max-width: 25vw;
        margin: 1em .5em;
        @include media-breakpoint-up(xl) {
            max-height: 4em;
            margin: 1em 2em;
        }
    }
}