/* Header */   

.siteHeader-wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    @include media-breakpoint-up(xxl) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.siteHeader {
    @include layout-padding;
    height: $max-element-height;
}

.siteHeader a,
.siteHeader a:not([href]) {
    font-weight: 300;
    &:hover {
        color: $secondary-colour;
    }
}

.siteHeader-logo {
    min-width: 150px;
}

// Navigation

.siteNav-wrapper {
    position: relative;
    @include media-breakpoint-down(xxl) {
        background-color: $dark-grey-colour;
        height: $max-mobile-nav-height;
    }
}

body:not(.MarketView) {
    .siteNav-wrapper {
        @include media-breakpoint-down(xxl){
            padding-right: $max-element-height;
        }
    }
}

.MarketShareView .siteNav-wrapper {
    @include media-breakpoint-down(xxl) {
        display: none;
    }
}

.siteNav {
    @include layout-padding;
    overflow-x: scroll;
}

.siteNav-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.siteNav-list li + li {
    margin-left: map-get( $spacers, 4 );
}

.siteNav-list-item a,
.siteNav-list-item a:not([href]) {
    display: block;
    font-weight: 600;
    white-space: nowrap;
    padding: map-get( $spacers, 1);
    @include media-breakpoint-down(xxl) {
        color: rgba(white, 0.6);
        /*padding: map-get( $spacers, 4);*/
    }
}

.siteNav-list-item a:not([href]) {
    cursor: pointer;
}

.siteNav-list-item a.is-active,
.siteNav-list-item a:not([href]).is-active {
    color: $secondary-colour;
    @include media-breakpoint-down(xxl) {
        color: white;
    }
}

.siteNav-toggle-filters {
    background-color: black;
    padding: 0;
    position: absolute;
    height: $max-element-height;
    width: $max-element-height;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    @include media-breakpoint-up(xxl) {
        display: none;
    }
}

.MarketShareView .siteNav-toggle-filters {
    display: none;
}