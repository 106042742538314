/* Typography */

a,
a:not([href]) {
    color: $primary-colour;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}