.fer-complexNavElement {
  align-items: center;
  justify-content: space-between;
  height: 20px;
}

.fer-complexNavElement-logo-wrapper {
  margin-right: 20px;
}

.fer-complexNavElement-svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}

.fer-complexNavElement-svg-wrapper button {
  width: 100%;
}

.fer-complexNavElement-svg {
  width: 40px;
  height: 40px;
}

.fer-complexNavElementData svg {
  width: 30px;
  height: 30px;
}

.fer-header {
  font-family: "Sarabun-Regular", sans-serif;
}

button.fer-complexNavElementData {
  width: 100%;
}

/* TODO: This should be part of the js map instead of using CSS  */

/*
.fer-complexNavElement:nth-child(13) .fer-complexNavElement-svg{transform: scale(1)}
.fer-complexNavElement:nth-child(12) .fer-complexNavElement-svg{transform: scale(0.9230)}
.fer-complexNavElement:nth-child(11) .fer-complexNavElement-svg{transform: scale(0.8461)}
.fer-complexNavElement:nth-child(10) .fer-complexNavElement-svg{transform: scale(0.7692)}
.fer-complexNavElement:nth-child(9) .fer-complexNavElement-svg{transform: scale(0.6923)}
.fer-complexNavElement:nth-child(8) .fer-complexNavElement-svg{transform: scale(0.6153)}
.fer-complexNavElement:nth-child(7) .fer-complexNavElement-svg{transform: scale(0.5384)}
.fer-complexNavElement:nth-child(6) .fer-complexNavElement-svg{transform: scale(0.4615)}
.fer-complexNavElement:nth-child(5) .fer-complexNavElement-svg{transform: scale(0.3846)}
.fer-complexNavElement:nth-child(4) .fer-complexNavElement-svg{transform: scale(0.3076)}
.fer-complexNavElement:nth-child(3) .fer-complexNavElement-svg{ transform: scale(0.2307)}
.fer-complexNavElement:nth-child(2) .fer-complexNavElement-svg{ transform: scale(0.1538)}
.fer-complexNavElement:nth-child(1) .fer-complexNavElement-svg{ transform: scale(0.0769)}*/
