.clubCarousel-panel-wrapper.mobile {
    @include media-breakpoint-up(lg) {
        display: none;
    }
    
    .clubCarousel-panel-header {
        @include layout-padding;
        height: $max-element-height;
        p {
            margin: 0;
        }
    }
    .swiper-container {
        background-color: white;
    }
    .swiper-slide {
        @include layout-padding;
        height: $max-element-height * 1.5;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
    .swiper-slide + .swiper-slide {
        border-left: 1px solid $light-grey-colour;
    }

    .clubCarousel-panel-swiper-logo {
        position: absolute;
        top: calc(50% - .5em);
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 5rem;
    }

    .clubCarousel-panel-swiper-title {
        margin-top: 1em;
    }
}

.clubCarousel-panel-wrapper.desktop {
    flex: 1;
    border-right: 1px solid;
    overflow: scroll;
    max-height:calc(100dvh - $max-element-height * 3);
    @include media-breakpoint-down(lg) {
        display: none;
    }

    .clubCarousel-panel {
        @include layout-padding;
        small {
            color: $dark-off-blue-colour;
        }
    }

    .clubCarousel-panel-title {
        margin-top: .25em;
    }
    
    .clubCarousel-panel-header {
        display: flex;
        justify-content: space-between;
        padding: 2em 0;
        @include media-breakpoint-up(lg) {
            height: 7rem;
        }
    }

    .clubCarousel-panel-header-logo {
        flex: 1;
        width: 50%;
        max-width: 10rem;
        @include media-breakpoint-up(lg) {
            height: 100%;
            // max-height: 4rem;
            max-width: none;
        }
    }

    .clubCarousel-panel-header-nav {
        flex: 3;
        text-align: right;
        button + button {
            margin-left: .5em;
        }
        button {
            all: unset;
            border: 0;
            padding: 0;
            &.previous {
                svg {
                    transform: rotate(180deg);
                }
            }
            svg {
                width: 1.5em;
                height: 1.5rem;
            }
            &:disabled svg path {
                fill: #A1ABB2;

            }
        }
    }
    
    .clubCarousel-panel-footer {
        margin-top: 1em;
    }
}

.MarketShareView {
    canvas.stacked-area-chart {
        width: calc(100% - 2rem);
    }
}