@keyframes slideInRight {
    to {
        transform: translateX(0%);
    }
}

@keyframes slideUp {
    to {
      height: 60px; 
    }
}

@keyframes fadeIn {
    to {
      opacity: 1;
    }
}

@keyframes slideDown {
    to {
      transform: translateY(0);
    }
}
  
@keyframes slideRight{  
    to {
      margin-right: 0;
    }
}