.siteFooter-wrapper {

    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 15;
    height: $max-element-height;
    background-color: black;

    // @media (max-width: 768px) {
    //     position: relative; 
    //     bottom: auto;
    // }
}

.siteFooter {
    display: flex;
    justify-content: space-between;
    color: white;
    }

@media screen and (max-width: 768px) {
    .siteFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        height: inherit;
        width: 100%;
        div {
            text-align: center;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.siteFooter-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 15;
    height: $max-element-height;
    background-color: black;
}



.siteFooter .download svg {
    margin-right: .5em;
    vertical-align: bottom;
}

#historicalGraphData svg {
    margin-right: .5em;
    vertical-align: bottom;
}