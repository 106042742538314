// Breakpoints

$grid-breakpoints: (
    xxs: 18em,
    xs: 27em,
    sm: 36em,
    md: 48em,
    lg: 62em,
    xl: 75em,
    xxl: 90em,
    xxxl: 100em,
    super: 120em,
    mega: 187.5em,
);

// Spacers

$spacers: (
  1: .25em,
  2: .5em,
  3: .75em,
  4: 1em,
  5: 1.5em,
  6: 2.5em,
);

//  Max widths

$max-width: 152.5ch;
$thead-column-width: 20em;

// Max heights

$max-element-height: 68px;
$max-mobile-nav-height: 68px;
$max-compare-height: 68px;


// Colours 

$primary-colour: #0A193F;
$secondary-colour: #009FE3;
$light-grey-colour: #EEEEEE;
$dark-grey-colour: #3F4B54;
$medium-grey-colour: #A1ABB2;
$off-blue-colour: #A1ABB2;
$dark-off-blue-colour: #495965;

// Checkbox colours
$blue: #0095C9;
$green: #009383;
$yellow: #FFB700;


