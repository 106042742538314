

// Swiper

.swiper-container {
    width: 100%;
    margin: 0;
}

.swiper-slide {
    flex: 1;
}

.fer-selectedCount-2 {
    .swiper-slide {
        @include media-breakpoint-up(lg) {
            max-width: calc(100vw / 2)
        }
        @include media-breakpoint-up(xxl) {
            max-width: calc(75vw / 2)
        }
    }
}

.fer-selectedCount-3 {
    .swiper-slide {
        @include media-breakpoint-up(lg) {
            max-width: calc(100vw / 3)
        }
        @include media-breakpoint-up(xxl) {
            max-width: calc(75vw / 3)
        }
    }
}

.is-table-view {

   .swiper-wrapper {
        @include media-breakpoint-down(lg) { 
            width: 100vw;
        }
   }
    .swiper-slide {
        @include media-breakpoint-down(lg) {  
            width: 100vw;
            max-width: 50vw;
        }
    }

    .swiper-pagination {
        bottom: calc($max-element-height + 1rem);
    }
}

.swiper-slide + .swiper-slide {
    @include media-breakpoint-up(lg) {
        border-left: 1px solid $dark-grey-colour!important;
    }
}

// Pagination

.swiper-pagination {
    position: fixed;
    bottom: calc($max-element-height + 0.5rem);
    left: 50%;
    transform: translateX(-50%)!important;
    button {
        all: unset;
        padding: .25rem;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}