/* Typography */

h1, .h1 {
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 1.5em;
    margin: map-get($spacers, 1) 0;
}

h2, .h2 {
    font-family: "Sarabun", sans-serif;
    font-weight: 300;
    font-size: 1.25em;
}

h3, .h3 {
    font-family: "Sarabun", sans-serif;
    font-size: 1em;
}

h4,h5,h6,
.h4, .h5, .h6 {
    font-family: "Sarabun", sans-serif;
    font-size: .75em;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
    font-weight: 600;
    margin: 0;
}

.font-secondary {
    font-family: "EB Garamond", serif;
}

.p {
    font-size: 1em;
    font-weight: normal;
}