@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
.spinner-icon {
  margin: 0 auto;
  border: 0.25rem solid #0094C7;
  border-top: 0.25rem solid transparent;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fer-complexNavElement {
  align-items: center;
  justify-content: space-between;
  height: 20px;
}

.fer-complexNavElement-logo-wrapper {
  margin-right: 20px;
}

.fer-complexNavElement-svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}

.fer-complexNavElement-svg-wrapper button {
  width: 100%;
}

.fer-complexNavElement-svg {
  width: 40px;
  height: 40px;
}

.fer-complexNavElementData svg {
  width: 30px;
  height: 30px;
}

.fer-header {
  font-family: "Sarabun-Regular", sans-serif;
}

button.fer-complexNavElementData {
  width: 100%;
}

/* TODO: This should be part of the js map instead of using CSS  */

/*
.fer-complexNavElement:nth-child(13) .fer-complexNavElement-svg{transform: scale(1)}
.fer-complexNavElement:nth-child(12) .fer-complexNavElement-svg{transform: scale(0.9230)}
.fer-complexNavElement:nth-child(11) .fer-complexNavElement-svg{transform: scale(0.8461)}
.fer-complexNavElement:nth-child(10) .fer-complexNavElement-svg{transform: scale(0.7692)}
.fer-complexNavElement:nth-child(9) .fer-complexNavElement-svg{transform: scale(0.6923)}
.fer-complexNavElement:nth-child(8) .fer-complexNavElement-svg{transform: scale(0.6153)}
.fer-complexNavElement:nth-child(7) .fer-complexNavElement-svg{transform: scale(0.5384)}
.fer-complexNavElement:nth-child(6) .fer-complexNavElement-svg{transform: scale(0.4615)}
.fer-complexNavElement:nth-child(5) .fer-complexNavElement-svg{transform: scale(0.3846)}
.fer-complexNavElement:nth-child(4) .fer-complexNavElement-svg{transform: scale(0.3076)}
.fer-complexNavElement:nth-child(3) .fer-complexNavElement-svg{ transform: scale(0.2307)}
.fer-complexNavElement:nth-child(2) .fer-complexNavElement-svg{ transform: scale(0.1538)}
.fer-complexNavElement:nth-child(1) .fer-complexNavElement-svg{ transform: scale(0.0769)}*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100dvh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

@keyframes slideInRight {
  to {
    transform: translateX(0%);
  }
}
@keyframes slideUp {
  to {
    height: 60px;
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}
@keyframes slideRight {
  to {
    margin-right: 0;
  }
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #F0F0F0;
  font-family: "Sarabun", sans-serif;
  min-width: 320px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/* Hide scrollbars */
*::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.siteBanner {
  padding: 1em;
  display: flex;
  align-items: center;
  height: 68px;
  color: white;
  background-image: radial-gradient(129.86% 1550.83% at 100% 100%, #009FE3 0%, #0A193F 50%, #000000 100%);
}
@media (min-width: 90em) {
  .siteBanner {
    padding: 1em 2.5em;
  }
}

@media (max-width: 89.98em) {
  body:not(.MarketShareView) .siteBanner {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

main {
  display: flex;
}

.clubCarousel-panel-wrapper {
  flex: 1 1;
  background-color: #FFFFFF;
}

.mainView-wrapper {
  flex: 3 1;
}

.main {
  height: calc(100dvh - 136px + 68px);
}
@media (min-width: 90em) {
  .main {
    height: calc(100dvh - 204px);
  }
}

.fer-rowWrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  height: 100%;
}

.fer-graphWrapper {
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex-grow: 1;
  transition: 0.25s;
}
.fer-graphWrapper:hover {
  text-decoration: none;
}

.fer-graphWrapper-header {
  min-height: 4rem;
  width: 100%;
}
.fer-graphWrapper-header p {
  margin-top: 0.5em;
}

.fer-graphWrapper-header-title {
  margin: 0;
  font-size: 1.125em;
}

.fer-graphBanner-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-self: flex-end;
  height: 100%;
}

.fer-graphBanner-button-wrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
}

.fer-graphBanner-button {
  padding: 1em;
  display: flex;
  flex: 1 1;
  align-items: center;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: black;
  justify-content: space-between;
  min-height: 68px;
  height: 100%;
  border-bottom: 4px solid;
  background-color: #EEEEEE;
  width: 100%;
}
@media (min-width: 90em) {
  .fer-graphBanner-button {
    padding: 1em 2.5em;
  }
}

/*
.fer-graphBanner-button-wrapper:nth-child(1n) .fer-graphBanner-button {
    border-color: $yellow;
}
.fer-graphBanner-button-wrapper:nth-child(2n) .fer-graphBanner-button {
    border-color: $green;
}
.fer-graphBanner-button-wrapper:nth-child(3n) .fer-graphBanner-button {
    border-color: $blue;
}
*/
button.fer-graphBanner-button-close {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0.25rem;
  right: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  z-index: 1;
}

button.fer-graphBanner-button-close:disabled {
  opacity: 0.25;
}

.fer-graphBanner-button-title {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 61.98em) {
  .fer-graphBanner-button-wrapper {
    transition: 0.25s;
  }
  .fer-graphBanner-button-wrapper.is-active {
    flex: 4 1;
  }
  .fer-graphBanner-button-wrapper:not(.is-active) .fer-graphBanner-button-title {
    display: none;
  }
}

.OverviewView .fer-graphWrapper-logo {
  position: absolute;
  max-width: 3.5rem;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .OverviewView .is-detail-view .fer-graphWrapper > .fer-graphWrapper-logo {
    padding: 0.5em !important;
  }
}

.DetailsView .fer-graphWrapper-logo {
  max-width: 3.5rem;
}

.market-view .fer-graphWrapper {
  padding: 1em;
}

.fer-gridWrapper {
  --gap: .25em;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: black;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  overflow: hidden;
  padding-bottom: 76px;
}
.fer-gridWrapper .fer-graphWrapper {
  position: relative;
}
@media (min-width: 769px) {
  .fer-gridWrapper .fer-graphWrapper {
    padding: 1em;
  }
}
.fer-gridWrapper .fer-graphWrapper::before, .fer-gridWrapper .fer-graphWrapper::after {
  content: "";
  position: absolute;
  background-color: var(--line-color);
  z-index: 1;
}
.fer-gridWrapper .fer-graphWrapper::after {
  inline-size: 100vw;
  block-size: var(--line-thickness);
  inset-inline-start: 0;
  inset-block-start: calc(var(--line-offset) * -1);
}
.fer-gridWrapper .fer-graphWrapper::before {
  inline-size: var(--line-thickness);
  block-size: 100dvh;
  inset-block-start: 0;
  inset-inline-start: calc(var(--line-offset) * -1);
}

.OverviewView .clubOverview-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}
.OverviewView .clubOverview-grid-item .fer-graphWrapper-logo {
  max-width: 8rem;
  max-height: 6vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 0.75em));
}
@media (min-width: 62em) {
  .OverviewView .clubOverview-grid-item .fer-graphWrapper-logo {
    transform: translate(-50%, -50%);
  }
}
.OverviewView .clubOverview-grid-item h3 {
  text-align: left;
  color: black;
}

.OverviewView .fer-gridWrapper {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, calc((100dvh - 136px - 68px) / 6)));
}
@media (min-width: 62em) {
  .OverviewView .fer-gridWrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(4, calc((100dvh - 136px - 68px) / 4));
  }
}
@media (min-width: 75em) {
  .OverviewView .fer-gridWrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, calc((100dvh - 204px) / 3)));
  }
}
.OverviewView .fer-graphWrapper {
  background-color: white;
}

.MarketShareView .fer-gridWrapper {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, calc((100dvh - 272px) / 2)));
}
@media (min-width: 62em) {
  .MarketShareView .fer-gridWrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, calc((100dvh - 204px) / 2)));
  }
}

.MarketShareView {
  overflow: scroll;
}
@media (max-width: 61.98em) {
  .MarketShareView main {
    flex-direction: column;
  }
}
@media (max-width: 61.98em) {
  .MarketShareView .fer-gridWrapper {
    border-top: 1px solid black;
  }
}
.MarketShareView .fer-graphWrapper {
  background-color: #EEEEEE;
}
.MarketShareView .fer-graphWrapper canvas.doughnut-chart {
  height: 15vw !important;
  width: auto !important;
}
@media (min-width: 36em) {
  .MarketShareView .fer-graphWrapper canvas.doughnut-chart {
    height: 10vw !important;
  }
}
.MarketShareView .fer-graphWrapper canvas.stacked-bar-chart {
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
  /* For smaller screens (mobile-first approach) */
  /* For larger screens (e.g., XL screens) */
}
@media (min-width: 36em) {
  .MarketShareView .fer-graphWrapper canvas.stacked-bar-chart {
    height: 12.5dvh !important; /* Set height as a fraction of viewport height */
    width: auto !important; /* Let the width adjust according to the aspect ratio */
  }
}
@media (min-width: 75em) {
  .MarketShareView .fer-graphWrapper canvas.stacked-bar-chart {
    height: 25dvh !important; /* Adjust height as needed */
    width: auto !important; /* Keep width proportional */
  }
}

@media (max-width: 61.98em) {
  .DetailsView .mainView:not(.is-table-view) .fer-rowWrapper {
    overflow-x: scroll;
    width: 100vw;
  }
  .DetailsView .mainView:not(.is-table-view) .fer-rowWrapper .fer-graphWrapper {
    overflow: scroll;
    min-width: 100vw;
  }
}
.DetailsView .fer-graphWrapper {
  background-color: white;
}
.DetailsView .chart-wrapper.table-chart-wrapper,
.DetailsView .mainView:not(.is-table-view) .fer-graphWrapper {
  height: calc(100dvh - 136px - 136px);
}
@media (min-width: 90em) {
  .DetailsView .chart-wrapper.table-chart-wrapper,
  .DetailsView .mainView:not(.is-table-view) .fer-graphWrapper {
    /*height: calc(100dvh - $max-element-height * 4.25);*/
  }
}
@media (min-width: 62em) {
  .DetailsView .fer-graphBanner-button-wrapper + .fer-graphBanner-button-wrapper,
  .DetailsView .fer-graphWrapper + .fer-graphWrapper {
    border-left: 1px solid #3F4B54 !important;
  }
}
@media (max-width: 61.98em) {
  .DetailsView .fer-graphBanner-button-wrapper.is-active .fer-graphBanner-button {
    background-color: white;
  }
}

/* Chart wrapper styling based on context */
.chart-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
}

.chart-wrapper.table-chart-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  height: 100%;
  border-collapse: collapse;
  height: calc(100% - 85px);
  overflow-y: scroll;
}
@media (max-width: 61.98em) {
  .chart-wrapper.table-chart-wrapper tbody {
    max-width: 50vw;
  }
}
@media (min-width: 62em) {
  .chart-wrapper.table-chart-wrapper tbody {
    width: 75%;
  }
}
.chart-wrapper.table-chart-wrapper thead {
  background-color: #EEEEEE;
  height: 100%;
  position: relative;
  width: 50vw;
}
@media (min-width: 62em) {
  .chart-wrapper.table-chart-wrapper thead {
    width: 25%;
  }
}
.chart-wrapper.table-chart-wrapper thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.chart-wrapper.table-chart-wrapper thead tr td {
  border-bottom: 1px solid rgb(217.0701754386, 221.1052631579, 223.9298245614);
}
@media (min-width: 62em) {
  .chart-wrapper.table-chart-wrapper thead tr td {
    border-right: 1px solid #3F4B54;
  }
}
@media (max-width: 61.98em) {
  .chart-wrapper.table-chart-wrapper thead tr td {
    font-size: small;
  }
}
.chart-wrapper.table-chart-wrapper thead tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10em;
}
@media (min-width: 62em) {
  .chart-wrapper.table-chart-wrapper thead tr td + td {
    border-left: 1px solid rgb(217.0701754386, 221.1052631579, 223.9298245614);
  }
}
.chart-wrapper.table-chart-wrapper thead td {
  padding: 1em;
  font-weight: 600;
  min-height: 12.5dvh;
  height: calc((100dvh - 204px - 85px) / 8);
}
@media (min-width: 90em) {
  .chart-wrapper.table-chart-wrapper thead td {
    padding: 1em 2.5em;
  }
}
.chart-wrapper.table-chart-wrapper thead .table-chart-header-footer td {
  color: #0A193F;
  border-bottom: 0;
}

.table-chart-header {
  padding: 1em;
  display: flex;
  align-items: center;
  width: 25%;
  height: 85px;
  border-bottom: 1px solid rgb(217.0701754386, 221.1052631579, 223.9298245614);
  border-right: 1px solid;
}
@media (min-width: 90em) {
  .table-chart-header {
    padding: 1em 2.5em;
  }
}
@media (max-width: 61.98em) {
  .table-chart-header {
    display: none;
  }
}

.MarketShareView .chart-wrapper,
.OverviewView .chart-wrapper {
  flex-direction: row-reverse;
  justify-content: center;
}
@media (max-width: 768px) {
  .MarketShareView .chart-wrapper,
  .OverviewView .chart-wrapper {
    padding-right: 10%;
    justify-content: right;
  }
}

.chart-wrapper .period-label {
  padding: 1em 0;
  text-align: center;
  font-weight: bold;
  color: black;
}

.chart-wrapper .values-row {
  display: flex;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  font-size: small;
  font-weight: 600;
}

@media (max-width: 61.98em) {
  .OverviewView .chart-wrapper .values-row {
    display: none;
  }
}

.legend-text {
  white-space: nowrap;
  overflow: hidden;
  color: black;
}

.chart-wrapper .legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 1em 3em 1em;
  font-size: small;
}

.chart-wrapper .legend-item {
  display: flex;
  align-items: center;
}
.chart-wrapper .legend-item .legend-color {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

svg.half-circle-chart .half-radial-path-group {
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .DetailsView .displayNone {
    display: none !important;
  }
}
.DetailsView #historicalGraphData {
  background-color: #009FE3;
}
.DetailsView .chart-wrapper:not(.table-chart-wrapper) {
  padding: 0 1em;
}
.DetailsView table.table-chart {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
@media (max-width: 61.98em) {
  .DetailsView table.table-chart {
    min-width: 50vw;
  }
  .DetailsView table.table-chart td {
    font-size: small;
  }
}
.DetailsView table.table-chart .table-chart-heading {
  font-weight: 600;
  color: #0A193F;
}
.DetailsView table.table-chart tbody tr td tr:last-child td.table-chart-value {
  border-bottom: 0;
}
.DetailsView table.table-chart tr {
  display: table;
  width: 100%;
}
.DetailsView table.table-chart tr td {
  border-bottom: 1px solid rgb(217.0701754386, 221.1052631579, 223.9298245614);
  text-align: left;
  width: 50%;
  height: calc((100dvh - 204px - 85px) / 8);
  padding: 0;
}
.DetailsView table.table-chart tr td tr td {
  padding: 1em;
}
@media (min-width: 90em) {
  .DetailsView table.table-chart tr td tr td {
    padding: 1em 2.5em;
  }
}
.DetailsView table.table-chart .table-chart-column {
  background-color: white;
  border: 0;
  padding: 0;
}
@media (min-width: 90em) {
  .DetailsView table.table-chart tr td + td {
    border-left: 1px solid rgb(217.0701754386, 221.1052631579, 223.9298245614);
  }
}
.DetailsView table.table-chart td.table-chart-value {
  max-width: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 61.98em) {
  .DetailsView table.table-chart td.table-chart-value {
    font-weight: 600;
    color: #009FE3;
  }
}
.DetailsView table.table-chart .table-chart-footer td {
  padding: 1em;
  color: white;
  background-color: #009FE3;
  border-bottom: 0;
}
@media (min-width: 90em) {
  .DetailsView table.table-chart .table-chart-footer td {
    padding: 1em 2.5em;
  }
}
.DetailsView table.table-chart .table-chart-footer td a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  display: block;
}
.DetailsView table.table-chart .table-chart-footer td svg {
  vertical-align: middle;
  margin-right: 0.25em;
  transform: translateY(-3px);
}
.DetailsView svg.linear-chart {
  height: 100%;
  width: 100%;
}
.DetailsView .stacked-bar-chart-wrapper {
  height: 100%;
  padding: 1em 0;
}
.DetailsView canvas.stacked-bar-chart {
  width: 12rem !important;
  height: auto !important;
}
.DetailsView .chart-wrapper.stacked-area-chart-wrapper {
  justify-content: center;
}
.DetailsView canvas.stacked-area-chart {
  width: 100% !important;
}
.DetailsView canvas.doughnut-chart {
  height: 25vh !important;
}
@media (min-width: 62em) {
  .DetailsView .swiper-slide:not(:first-child) .chart-wrapper .legend,
  .DetailsView .fer-graphWrapper:not(:first-child) .chart-wrapper .legend {
    opacity: 0;
  }
}

.OverviewView svg.linear-chart {
  width: 10vw;
  height: 10vw;
}
.OverviewView svg.half-circle-chart {
  width: 7vw;
  height: 7vw;
}
.OverviewView canvas.doughnut-chart {
  width: 7vw !important;
  height: 7vw !important;
}
.OverviewView canvas.stacked-area-chart {
  width: 30vw !important;
  height: auto !important;
}
@media (min-width: 48em) {
  .OverviewView canvas.stacked-area-chart {
    width: 20vw !important;
  }
}
@media (min-width: 62em) {
  .OverviewView canvas.stacked-area-chart {
    width: 15vw !important;
  }
}
.OverviewView .stacked-bar-chart-wrapper {
  width: 7rem !important;
  height: 5rem !important;
}
@media (min-width: 62em) {
  .OverviewView .stacked-bar-chart-wrapper {
    width: 6.5rem !important;
    height: 90% !important;
  }
}
.OverviewView .chart-wrapper .values-row {
  position: absolute;
  bottom: -0.75em;
}
.OverviewView .chart-wrapper .values-row.previous {
  display: none;
}
.OverviewView .chart-wrapper .period-label {
  display: none;
}
.OverviewView .chart-wrapper .legend {
  display: none;
}

.MarketShareView .stacked-area-chart-wrapper {
  width: 100%;
}
.MarketShareView .stacked-bar-chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .MarketShareView .stacked-bar-chart-wrapper {
    width: 12.5rem; /* Apply this width on devices larger than 768px */
  }
}

@media (min-width: 62em) {
  .clubCarousel-panel-wrapper.mobile {
    display: none;
  }
}
.clubCarousel-panel-wrapper.mobile .clubCarousel-panel-header {
  padding: 1em;
  height: 68px;
}
@media (min-width: 90em) {
  .clubCarousel-panel-wrapper.mobile .clubCarousel-panel-header {
    padding: 1em 2.5em;
  }
}
.clubCarousel-panel-wrapper.mobile .clubCarousel-panel-header p {
  margin: 0;
}
.clubCarousel-panel-wrapper.mobile .swiper-container {
  background-color: white;
}
.clubCarousel-panel-wrapper.mobile .swiper-slide {
  padding: 1em;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (min-width: 90em) {
  .clubCarousel-panel-wrapper.mobile .swiper-slide {
    padding: 1em 2.5em;
  }
}
.clubCarousel-panel-wrapper.mobile .swiper-slide + .swiper-slide {
  border-left: 1px solid #EEEEEE;
}
.clubCarousel-panel-wrapper.mobile .clubCarousel-panel-swiper-logo {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 5rem;
}
.clubCarousel-panel-wrapper.mobile .clubCarousel-panel-swiper-title {
  margin-top: 1em;
}

.clubCarousel-panel-wrapper.desktop {
  flex: 1 1;
  border-right: 1px solid;
  overflow: scroll;
  max-height: calc(100dvh - 204px);
}
@media (max-width: 61.98em) {
  .clubCarousel-panel-wrapper.desktop {
    display: none;
  }
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel {
  padding: 1em;
}
@media (min-width: 90em) {
  .clubCarousel-panel-wrapper.desktop .clubCarousel-panel {
    padding: 1em 2.5em;
  }
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel small {
  color: #495965;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-title {
  margin-top: 0.25em;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header {
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
}
@media (min-width: 62em) {
  .clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header {
    height: 7rem;
  }
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-logo {
  flex: 1 1;
  width: 50%;
  max-width: 10rem;
}
@media (min-width: 62em) {
  .clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-logo {
    height: 100%;
    max-width: none;
  }
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav {
  flex: 3 1;
  text-align: right;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav button + button {
  margin-left: 0.5em;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav button {
  all: unset;
  border: 0;
  padding: 0;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav button.previous svg {
  transform: rotate(180deg);
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav button svg {
  width: 1.5em;
  height: 1.5rem;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-header-nav button:disabled svg path {
  fill: #A1ABB2;
}
.clubCarousel-panel-wrapper.desktop .clubCarousel-panel-footer {
  margin-top: 1em;
}

.MarketShareView canvas.stacked-area-chart {
  width: calc(100% - 2rem);
}

.filterPanel-wrapper {
  background-color: #0A193F;
  position: relative;
  z-index: 3;
  flex: 1 1;
  height: calc(100dvh - 136px + 68px);
}
@media (min-width: 90em) {
  .filterPanel-wrapper {
    height: calc(100dvh - 204px);
  }
}
@media (max-width: 89.98em) {
  .filterPanel-wrapper {
    position: fixed;
    top: 136px;
    width: 100%;
    height: calc(100dvh - 68px - 68px);
    transform: translateX(100%);
    transition: 0.5s;
    z-index: 10;
  }
  .filterPanel-wrapper.is-active {
    transform: translateX(0%);
  }
}

.filterPanel-inner-wrapper {
  overflow: scroll;
}
@media (max-width: 74.98em) {
  .filterPanel-inner-wrapper {
    height: calc(100dvh - 204px - 68px);
  }
}

.filterPanel-wrapper-header {
  display: flex;
  align-items: center;
  height: 68px;
  font-weight: 600;
  padding: 1.75em 1em;
  background-color: #EEEEEE;
  color: black;
}
@media (max-width: 89.98em) {
  .filterPanel-wrapper-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
@media (min-width: 90em) {
  .filterPanel-wrapper-header {
    padding: 1.25em 1em;
    background-color: black;
    color: #A1ABB2;
  }
}

.filterPanel-wrapper-header p {
  margin: 0;
}

.filterPanel-form {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}
@media (min-width: 90em) {
  .filterPanel-form {
    height: calc(100dvh - 204px + 68px);
  }
}
@media (min-width: 90em) and (min-width: 90em) {
  .filterPanel-form {
    height: calc(100dvh - 340px);
  }
}

.filterPanel-form-input-wrapper {
  width: 50%;
}
@media (min-width: 90em) {
  .filterPanel-form-input-wrapper {
    width: 100%;
  }
}

.filterPanel-form-checkbox {
  position: relative;
}

.filterPanel-form-checkbox label {
  padding: 1.75em 1em 1.75em 2.5em;
  border-left: 0.5em solid #0A193F;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
}
@media (min-width: 90em) {
  .filterPanel-form-checkbox label {
    padding: 1em 1em 1em 2em;
  }
}

.filterPanel-form-checkbox input[type=checkbox] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  -webkit-appearance: none;
          appearance: none;
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid #A1ABB2;
  background-color: #495965;
  margin: 0 0.5em 0 0;
}
.filterPanel-form-checkbox input[type=checkbox]::before {
  content: "";
  width: 1em;
  height: 1em;
}

.filterPanel-form-checkbox label:hover input[type=checkbox]:checked + label,
.filterPanel-form-input-wrapper input[type=checkbox]:checked + label {
  background-color: #495965;
}

/*.filterPanel-form-input-wrapper:nth-child(1n) {
    input[type="checkbox"]:checked + label {
        border-color: $blue;
    }
    input[type="checkbox"]:checked {
        background-color: $blue;
    }
}

.filterPanel-form-input-wrapper:nth-child(2n) {
    input[type="checkbox"]:checked + label {
        border-color: $green;
    }
    input[type="checkbox"]:checked {
        background-color: $green;
    }
}

.filterPanel-form-input-wrapper:nth-child(3n) {
    input[type="checkbox"]:checked + label {
        border-color: $yellow;
    }
    input[type="checkbox"]:checked {
        background-color: $yellow;
    }
}*/
.filterPanel-footer {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  background-color: #3F4B54;
  bottom: 68px;
  height: 68px;
}
@media (min-width: 90em) {
  .filterPanel-footer {
    width: 25%;
  }
}

.filterPanel-footer-button {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  width: 50%;
}
.filterPanel-footer-button .filterPanel-footer-button-text {
  width: calc(100% - 20px);
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filterPanel-footer-button svg {
  width: 20px;
  margin: 0.25rem;
  flex-shrink: 0;
  transform: translateX(-0.5em);
}
.filterPanel-footer-button.next .filterPanel-footer-button-text {
  text-align: right;
}
.filterPanel-footer-button.previous svg {
  transform: rotate(180deg) translateX(-0.5em);
}

.filterPanel-form-apply-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: #009FE3;
  color: white;
}
@media (min-width: 90em) {
  .filterPanel-form-apply-filters {
    display: none;
  }
}
.filterPanel-form-apply-filters:hover {
  background-color: rgb(0, 141.1387665198, 201.5);
}
.filterPanel-form-apply-filters:disabled {
  color: rgba(255, 255, 255, 0.6);
}
.filterPanel-form-apply-filters:disabled svg {
  opacity: 0.6;
}

.filterPanel-form-scroll-text {
  color: white;
  position: fixed;
  padding: 1em;
  right: 1em;
  pointer-events: none;
  bottom: 136px;
  transition: 0.25s;
  transition: 0.5s;
  opacity: 0;
}

.filterPanel-form.has-overflow .filterPanel-form-scroll-text {
  opacity: 0.75;
}

.filterPanel-form.has-scrolled .filterPanel-form-scroll-text,
.filterPanel-form:hover .filterPanel-form-scroll-text {
  opacity: 0;
}

.swiper-container {
  width: 100%;
  margin: 0;
}

.swiper-slide {
  flex: 1 1;
}

@media (min-width: 62em) {
  .fer-selectedCount-2 .swiper-slide {
    max-width: 50vw;
  }
}
@media (min-width: 90em) {
  .fer-selectedCount-2 .swiper-slide {
    max-width: 37.5vw;
  }
}

@media (min-width: 62em) {
  .fer-selectedCount-3 .swiper-slide {
    max-width: 33.3333333333vw;
  }
}
@media (min-width: 90em) {
  .fer-selectedCount-3 .swiper-slide {
    max-width: 25vw;
  }
}

@media (max-width: 61.98em) {
  .is-table-view .swiper-wrapper {
    width: 100vw;
  }
}
@media (max-width: 61.98em) {
  .is-table-view .swiper-slide {
    width: 100vw;
    max-width: 50vw;
  }
}
.is-table-view .swiper-pagination {
  bottom: calc(68px + 1rem);
}

@media (min-width: 62em) {
  .swiper-slide + .swiper-slide {
    border-left: 1px solid #3F4B54 !important;
  }
}

.swiper-pagination {
  position: fixed;
  bottom: calc(68px + 0.5rem);
  left: 50%;
  transform: translateX(-50%) !important;
}
.swiper-pagination button {
  all: unset;
  padding: 0.25rem;
}
@media (min-width: 62em) {
  .swiper-pagination {
    display: none;
  }
}

/* Header */
.siteHeader-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
}
@media (min-width: 90em) {
  .siteHeader-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.siteHeader {
  padding: 1em;
  height: 68px;
}
@media (min-width: 90em) {
  .siteHeader {
    padding: 1em 2.5em;
  }
}

.siteHeader a,
.siteHeader a:not([href]) {
  font-weight: 300;
}
.siteHeader a:hover,
.siteHeader a:not([href]):hover {
  color: #009FE3;
}

.siteHeader-logo {
  min-width: 150px;
}

.siteNav-wrapper {
  position: relative;
}
@media (max-width: 89.98em) {
  .siteNav-wrapper {
    background-color: #3F4B54;
    height: 68px;
  }
}

@media (max-width: 89.98em) {
  body:not(.MarketView) .siteNav-wrapper {
    padding-right: 68px;
  }
}

@media (max-width: 89.98em) {
  .MarketShareView .siteNav-wrapper {
    display: none;
  }
}

.siteNav {
  padding: 1em;
  overflow-x: scroll;
}
@media (min-width: 90em) {
  .siteNav {
    padding: 1em 2.5em;
  }
}

.siteNav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.siteNav-list li + li {
  margin-left: 1em;
}

.siteNav-list-item a,
.siteNav-list-item a:not([href]) {
  display: block;
  font-weight: 600;
  white-space: nowrap;
  padding: 0.25em;
}
@media (max-width: 89.98em) {
  .siteNav-list-item a,
  .siteNav-list-item a:not([href]) {
    color: rgba(255, 255, 255, 0.6);
    /*padding: map-get( $spacers, 4);*/
  }
}

.siteNav-list-item a:not([href]) {
  cursor: pointer;
}

.siteNav-list-item a.is-active,
.siteNav-list-item a:not([href]).is-active {
  color: #009FE3;
}
@media (max-width: 89.98em) {
  .siteNav-list-item a.is-active,
  .siteNav-list-item a:not([href]).is-active {
    color: white;
  }
}

.siteNav-toggle-filters {
  background-color: black;
  padding: 0;
  position: absolute;
  height: 68px;
  width: 68px;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
}
@media (min-width: 90em) {
  .siteNav-toggle-filters {
    display: none;
  }
}

.MarketShareView .siteNav-toggle-filters {
  display: none;
}

.siteFooter-wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 15;
  height: 68px;
  background-color: black;
}

.siteFooter {
  display: flex;
  justify-content: space-between;
  color: white;
}

@media screen and (max-width: 768px) {
  .siteFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: inherit;
    width: 100%;
  }
  .siteFooter div {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.siteFooter-wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 15;
  height: 68px;
  background-color: black;
}

.siteFooter .download svg {
  margin-right: 0.5em;
  vertical-align: bottom;
}

#historicalGraphData svg {
  margin-right: 0.5em;
  vertical-align: bottom;
}

/* Typography */
a,
a:not([href]) {
  color: #0A193F;
  text-decoration: none;
}
a:hover, a:focus,
a:not([href]):hover,
a:not([href]):focus {
  text-decoration: underline;
}

dl {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}

dt {
  clear: both;
  width: 75%;
}

dt, dd {
  color: #495965;
  font-weight: 600;
  float: left;
  padding: 0;
  margin: 0.5em 0;
}
@media (min-width: 90em) {
  dt, dd {
    margin: 1.25em 0;
  }
}
@media only screen and (max-height: 940px) {
  dt, dd {
    margin: 0.5em 0;
  }
}

dd {
  text-align: right;
  color: #009FE3;
  font-weight: 600;
  width: 25%;
}

.button {
  display: flex;
  width: max-content;
  align-items: center;
  padding: 0.25em 0.5em;
  color: white;
  font-weight: 600;
  background-color: #009FE3;
}
.button svg {
  width: 1.75em;
  height: 1.75em;
  margin-left: 0.25em;
}

/* Splash styles */
.splash {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100dvh;
  width: 100%;
  padding: 4rem;
  opacity: 1;
  overflow: hidden;
  background-color: #0A193F;
  background-image: url(/static/media/splash-bg.6a8c09ea.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.splash-header,
.splash-footer {
  width: 100%;
  opacity: 0;
  animation: fadeIn forwards 2s;
}

.splash-logo,
.splash-title img,
.splash-quote img {
  margin: 0 auto;
}

.splash-title {
  margin: 4em 0;
}

.splash-quote {
  font-size: 2.5em;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: "EB Garamond", serif;
  color: white;
  margin: 1em 0;
}
@media (min-width: 90em) {
  .splash-quote {
    margin: 3em 0;
  }
}

.splash-footer-logos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.splash-footer-logos img {
  max-width: 25vw;
  margin: 1em 0.5em;
}
@media (min-width: 75em) {
  .splash-footer-logos img {
    max-height: 4em;
    margin: 1em 2em;
  }
}

/* Typography */
h1, .h1 {
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  margin: 0.25em 0;
}

h2, .h2 {
  font-family: "Sarabun", sans-serif;
  font-weight: 300;
  font-size: 1.25em;
}

h3, .h3 {
  font-family: "Sarabun", sans-serif;
  font-size: 1em;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-family: "Sarabun", sans-serif;
  font-size: 0.75em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  margin: 0;
}

.font-secondary {
  font-family: "EB Garamond", serif;
}

.p {
  font-size: 1em;
  font-weight: normal;
}
